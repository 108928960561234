.ui.vertical.sidebar.menu {
  > .item {
    border-top: solid 1px #393c3a;
  }
}

.ui.top.sidebar.mobilemenu {
  background-color: #000000;
}

.sidebar .servicelink {
  border-top: 1px solid #393c3a;

  a {
    color: #b1b1b1;
  }
}

.unauthorised .logo span.slogan {
  color: #ffffff;
}
